import { ERoute } from '@App/Router';
import { faqSections } from '@Page/FAQ/Content';
import React, { useCallback, useState, useEffect } from 'react';
import { Block, List, OnList, OnListItem, PageContainer, Row } from '@Shared/Layout';
import { usePriceFormatter } from '@Shared/Hook/PriceFormatter';
import { Colors, Description, Heading2, Heading3, Heading4, mediaLaptopXSmall } from '@Shared/Style';
import { CompanyList, Arch } from '@Shared/Component';
import { usePaddlePrice } from '@API/Paddle';
import { MAIL_SUPPORT, PADDLE_CUSTOM_CHECKOUT_URL, PADDLE_PRODUCT_ID } from '@Constant';
import { useCheckout } from '@API/Checkout';
import { useNavigate } from '@Shared/Hook/Navigate';
import { Helmet } from 'react-helmet-async';
import { constructSendMail, PaddleHelper, reachGoal } from '@Helper';
import { PricingOptionFeatured } from '@Shared/Component/PricingOptionFeatured';
import { PricingOption } from '@Shared/Component/PricingOption';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import gfm from 'remark-gfm';
import remarkSlug from 'remark-slug';
import styled from 'styled-components';
import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import pricing from '@Docs/Pricing.md';

export const PricingPage: React.FC = () => {
  const navigate = useNavigate();
  const history = useHistory();
  const { params } = useRouteMatch<{ hash: string }>();
  const [faqContent, setFaqContent] = useState<string | null>(null);
  const [count, setCount] = useState<number>(1);
  const [price, basePrice, currency, priceLoading] = usePaddlePrice({
    quantity: count,
    productId: PADDLE_PRODUCT_ID,
  });
  const { formatPrice } = usePriceFormatter();
  const { doCheckout, query, error } = useCheckout({
    productId: PADDLE_PRODUCT_ID,
    quantity: count,
  });
  const priceFormatted = formatPrice({
    price,
    count,
  });
  const basePriceFormatted = formatPrice({
    price: basePrice,
    count,
  });

  const onCheckout = useCallback(async () => {
    const resultUrl = await doCheckout();
    if (resultUrl) {
      Paddle.Checkout.open({ override: resultUrl });
      reachGoal('hero_paddle', {
        category: 'purchase',
      });
    }
  }, [doCheckout]);
  useEffect(() => {
    const onSuccess = (data: Paddle.PaddleEvent) => {
      navigate(ERoute.Checkout, {
        email: data.user?.email || '',
      })();
      reachGoal('purchase_success', {
        category: 'purchase',
        value: count,
      });
    };

    PaddleHelper.subscribe('Checkout.Success', onSuccess);

    return () => {
      PaddleHelper.unsubscribe('Checkout.Success', onSuccess);
    };
  }, [navigate, count]);

  const section = faqSections.find((s) => s.title === 'Troubleshooting');
  if (section?.items != undefined) {
    section.items = [section.items[0], section.items[1]];
  }

  useEffect(() => {
    // Open custom Paddle pay link once on page load.
    if (params.hash) {
      Paddle.Checkout.open({ override: `${PADDLE_CUSTOM_CHECKOUT_URL}/${params.hash}` });
      history.push(ERoute.Buy);
    }

    fetch(pricing)
      .then((res) => res.text())
      .then((markdown) => { setFaqContent(markdown); });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = 'Free GIF editor, converter, recorder, creator app – Gifox 🦊';
  const description = 'Gifox is a freemium app with optional one-time upgrade to Gifox Pro – if you want to support our team and ongoing app development.';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content="https://gifox.app/buy" />
        <meta property="og:image" content="https://gifox.app/image/gifox.gif" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <PricingBlock>
        <PageContainer>
          <Arch top="16rem" />
          <PricingOptionRow>
            <PricingOption title="Gifox Basic" price="Free" buttonTitle="Download" onClick={navigate(ERoute.Download)}>
              <OnList>
                <OnListItem>Unlimited imports & conversions</OnListItem>
                <OnListItem>Unlimited edits & re-exports</OnListItem>
                <OnListItem>“Made with Gifox” watermark</OnListItem>
                <OnListItem>10-second screen recording limit</OnListItem>
              </OnList>
            </PricingOption>
            <PricingOptionFeatured
              title="Gifox Pro"
              price={basePriceFormatted.totalPrice}
              count={count}
              onCountChange={setCount}
              totalPrice={priceFormatted.totalPrice}
              currency={currency}
              buttonTitle="Buy"
              buttonDisabled={query.isFetching || priceLoading || !price}
              onClick={onCheckout}
              error={error}
              isLoading={priceLoading || !price}
            >
              <OnList>
                <OnListItem>Removable watermark</OnListItem>
                <OnListItem>Unlimited screen recording</OnListItem>
                <OnListItem>One-time purchase & lifetime license</OnListItem>
                <OnListItem>Volume discounts for 3+ licenses</OnListItem>
              </OnList>
            </PricingOptionFeatured>
            <PricingOption title="Education Discount" price="30%" buttonTitle="Contact Us" onClick={constructSendMail(MAIL_SUPPORT, 'Education Discount')}>
              <Description>
                For students and teachers:<br />email us from your .edu address<br />to get the discount!
              </Description>
            </PricingOption>
          </PricingOptionRow>
        </PageContainer>
        <PageContainer>
          <FAQBlock>
            <ReactMarkdown
              remarkPlugins={[gfm, remarkSlug]}
              rehypePlugins={[rehypeRaw]}
              components={{
                h1: Heading2,
                h2: Heading3,
                h3: Heading4,
                ul: List,
                p: Description,
              }}
            >
              {faqContent || ''}
            </ReactMarkdown>
          </FAQBlock>
        </PageContainer>
        <Block textAlign="center" margin="4rem auto -1rem">
          <CompanyList>
            <Heading2 margin="0 0 0.6rem">Built for the best.</Heading2>
            <Description marginTop="0.4375rem" color={Colors.base3} maxWidth={424} margin="0 auto">
              Gifox is used by many teams in amazing companies <br />to exchange ideas, report bugs, help customers, <br />revive presentations and much more!
            </Description>
          </CompanyList>
        </Block>
      </PricingBlock>
      <Link to={ERoute.Checkout} />
    </>
  );
};

const PricingBlock = styled.div`
  box-sizing: border-box;
  position: relative;
`;

const PricingOptionRow = styled(Row)`
  flex-flow: row nowrap;
  ${mediaLaptopXSmall} {
    flex-direction: column;
    > * {
      margin-right: 0;
    }
  }
`;

const FAQBlock = styled.div`
  text-align: left;
  max-width: 48.75rem;
  margin: 5rem auto 1.5rem;

  h1, h2, h3 {
    margin: 2.725rem 0 0.9375rem;
  }
`;
