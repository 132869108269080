import { getDMG } from '@API/Distribution';
import { ERoute } from '@App/Router';
import { outerSize } from '@Helper';
import { remarkChangelog, remarkFlattenImage } from '@Helper/Remark';
import { PageNavigator } from '@Shared/Component';
import { usePageNavigator } from '@Shared/Hook/PageNavigator';
import { useScrollToElement } from '@Shared/Hook/ScrollToElement';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Block, DocumentationBlock, List, PageContainer } from '@Shared/Layout';
import { Colors, Description, ExternalLink, Fonts, Heading1, Heading2, Heading3, Heading4, mediaGeneralAdaptive, NavigatorScrollHashLink } from '@Shared/Style';
import semver from 'semver';
import styled, { css } from 'styled-components';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';
import remarkSlug from 'remark-slug';
import { Helmet } from 'react-helmet-async';
import { RouteComponentProps, useHistory, useLocation } from 'react-router-dom';
import changelog from '@Docs/Changelog.md';
import { ReactComponent as DownloadIcon } from '@Static/image/icon/download.svg';
import { ReactComponent as ChevronDownIcon } from '@Static/image/icon/chevron-down.svg';

export const ChangelogPage: React.FC<RouteComponentProps> = () => {
  const [releases, setReleases] = useState<Release[]>([]);
  const { navigator, sectionsContainer, currentSection } = usePageNavigator(256, true, [releases]);
  const [currentKey, setCurrentKey] = useState<string>('');
  const location = useLocation();
  const { scrollToElement, isScrolling } = useScrollToElement();
  const [currentMinor, setCurrentMinor] = useState<string>('downloads');
  const [isDownloadsOpen, setIsDownloadsOpen] = useState<boolean>(true);

  const downloadTree = useMemo(() => {
    const latestApplicationMajorVersion = releases[0]?.applicationVersion?.substring(0, 1);
    const limit = 5;
    const latestMinorCount = 4;
    return releases?.reduce((acc, release) => {
      if (acc.length > limit) { return acc; }
      const major = release.applicationVersion.substring(0, 1);
      if (acc.length > latestMinorCount && major == latestApplicationMajorVersion) { return acc; }
      if (acc[acc.length - 1]?.applicationVersion.substring(0, 3) == release.applicationVersion.substring(0, 3)) { return acc; }
      acc.push(release);
      return acc;
    }, [] as Release[]);
  }, [releases]);

  const navigationTree = useMemo(() => {
    const tree = {} as Record<string, Release[]>;
    releases.forEach((version) => {
      const minor = version.applicationVersion.substring(0, 3);
      if (tree[minor]) {
        tree[minor].push(version);
      } else {
        tree[minor] = [version];
      }
    });
    return tree;
  }, [releases]);

  const downloadableVersions = useMemo(() => {
    const latestApplicationMajorVersion = releases[0]?.applicationVersion?.substring(0, 1);
    const latestApplicationMinorVersion = releases[0]?.applicationVersion?.substring(0, 3);
    const limit = 5;
    const latestMinorCount = 4;
    return releases?.reduce((acc, release) => {
      if (acc.length > limit) { return acc; }
      const major = release.applicationVersion.substring(0, 1);
      if (acc.length > latestMinorCount && major == latestApplicationMajorVersion) { return acc; }
      if (acc[acc.length - 1]?.substring(0, 3) != latestApplicationMinorVersion && acc[acc.length - 1]?.substring(0, 3) == release.applicationVersion.substring(0, 3)) { return acc; }
      acc.push(release.applicationVersion);
      return acc;
    }, [] as string[]);
  }, [releases]);

  useEffect(() => {
    const newKey = location.hash.substring(1);
    if (newKey && newKey != currentKey && !isScrolling) {
      setCurrentKey(newKey);
      scrollToElement(`#${newKey}`);
    }
  }, [location.hash, currentKey, scrollToElement, isScrolling]);

  useEffect(() => {
    if (!currentSection || isScrolling) { return; }
    const viewingMinor = Array.from(currentSection.replace("version_", "").substring(0, 2)).join(".");
    if (viewingMinor && window.scrollY > 0) {
      setCurrentMinor(viewingMinor);
      setIsDownloadsOpen(false);
    }
  }, [currentSection, isScrolling]);

  useEffect(() => {
    fetch(changelog)
      .then((res) => res.text())
      .then((markdown) => {
        setReleases(parseMarkdownReleases(markdown));
      });
  }, []);

  const toggleDownloads = useCallback(() => {
    setIsDownloadsOpen(!isDownloadsOpen);
    setCurrentMinor('');
  }, [isDownloadsOpen]);

  const toggleVersionGroup = useCallback((version?: string) => {
    if (!version) { return; }
    setCurrentMinor(version);
    setIsDownloadsOpen(false);
  }, []);

  const title = 'Features, improvements and updates – Gifox Changelog 🦊';
  const description = 'Find out about releases and what’s changed in recent versions of Gifox – a foxy app for creating, converting and editing GIFs designed for your Mac!';

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta property="og:title" content={title} />
        <meta property="og:locale" content="en" />
        <meta property="og:url" content="https://gifox.app/changelog" />
        <meta property="og:image" content="https://gifox.app/image/gifox.gif" />
        <meta property="og:type" content="website" />
        <meta property="og:description" content={description} />
        <meta name="description" content={description} />
      </Helmet>
      <PageContainer>
        <PageColumns>
          <VersionsSidebar className="mobile-hide">
            <VersionGroup id="downloads" title="Downloads" releases={downloadTree} open={isDownloadsOpen} showMeta onToggle={toggleDownloads} />
            <PageNavigator ref={navigator}>
              {Object.entries(navigationTree)
                .map(([minor, minorReleases]) => (
                  <VersionGroup key={minor} id={minor} title={`Version ${minor}`} releases={minorReleases} open={minor === currentMinor} onToggle={toggleVersionGroup} scrollOnToggle />
                ))}
            </PageNavigator>
          </VersionsSidebar>
          <Block ref={sectionsContainer} maxWidth="52rem">
            <ChangelogContainer>
              {releases.map((release) => (
                <section key={release.applicationVersion}>
                  <ChangelogHeading release={release} downloadable={downloadableVersions.some((downloadableVersion) => downloadableVersion == release.applicationVersion)} />
                  <ReactMarkdown
                    remarkPlugins={[gfm, remarkSlug, remarkChangelog, remarkFlattenImage]}
                    rehypePlugins={[rehypeRaw]}
                    components={{
                      h2: Heading4,
                      ul: List,
                      p: Description,
                    }}
                  >
                    {release.description}
                  </ReactMarkdown>
                </section>
              ))}
            </ChangelogContainer>
          </Block>
        </PageColumns>
      </PageContainer>
    </>
  );
};

const ChangelogHeading: React.FC<{ release: Release, downloadable: boolean }> = ({ release, downloadable }) => {
  const history = useHistory();
  const version = semver.parse(release.applicationVersion.replace('.00', ''));

  const linkHandler = useCallback(async (e: React.MouseEvent<HTMLAnchorElement>) => {
    e.preventDefault();
    const distributionUrl = await getDMG({ version: version?.raw ?? release.applicationVersion });
    if (distributionUrl) {
      history.push({
        pathname: ERoute.Download,
        state: { downloadUrl: distributionUrl },
      });
    } else {
      history.push({ pathname: ERoute.NotFound });
    }
  }, [history, version, release]);

  if (!version) {
    console.error("Unable to parse ChangelogHeading for the release:", release);
    return null;
  }

  const build = version.build[0] ?? '00';
  const filename = `${version.major.padStart(2, 0)}${version.minor.padStart(2, 0)}${version.patch.padStart(2, 0)}.${build.padStart(2, '0')}.dmg`;
  const url = `${ERoute.Download}/${filename}`;
  const date = moment(release.date, 'YYYY.MM.DD');
  return (
    <ChangelogInformationBlock>
      {downloadable ? (
        <ChangelogVersionLink id={`version_${version.major}${version.minor}${version.patch}`} href={url} onClick={linkHandler}>
          Version {version.version} <span><DownloadIcon /></span>
        </ChangelogVersionLink>
      ) : (
        <ChangelogVersion id={`version_${version.major}${version.minor}${version.patch}`}>Version {version.version}</ChangelogVersion>
      )}
      <ChangelogAdditional>{date.format('MMM DD, YYYY')} / {release.systemMinimumVersionPrefix} {release.systemMinimumVersion} — {release.systemTargetVersionPrefix} {release.systemTargetVersion}</ChangelogAdditional>
    </ChangelogInformationBlock>
  );
};

const VersionGroup: React.FC<{ id: string, title: string, releases: Release[], open: boolean, onToggle: (version?: string) => void, showMeta?: boolean, scrollOnToggle?: boolean }> = ({ id, title, releases, open, onToggle, showMeta, scrollOnToggle }) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [fullHeight, setFullHeight] = useState<number | undefined>(undefined);
  const history = useHistory();

  useEffect(() => {
    if (containerRef.current && containerRef.current.hasChildNodes()) {
      const itemSize = outerSize(containerRef.current.childNodes[0] as HTMLElement);
      setFullHeight(itemSize.height * releases.length);
    }
  }, [releases, containerRef]);

  const buildNavigatorLink = useCallback((key: string) => `${ERoute.Changelog}#version_${key.replaceAll('.', '')}`, []);
  const handleToggle = useCallback(() => {
    onToggle(id);
    if (scrollOnToggle) { history.push(buildNavigatorLink(releases[0].applicationVersion)); }
  }, [id, onToggle, scrollOnToggle, history, buildNavigatorLink, releases]);

  return (
    <VersionsGroup>
      <VersionsGroupToggle onClick={handleToggle} collapsed={!open}>
        {title}
        <ChevronDownIcon />
      </VersionsGroupToggle>
      <VersionsGroupItems style={{ height: open ? fullHeight : 0 }} ref={containerRef}>
        {releases.map((release) => (
          <VersionsGroupItem key={release.applicationVersion}>
            <NavigatorScrollHashLink key={release.applicationVersion} to={buildNavigatorLink(release.applicationVersion)}>
              Version {showMeta ? release.applicationVersion.substring(0, 3) : release.applicationVersion}
            </NavigatorScrollHashLink>
            {showMeta ? (
              <Description>{release.systemMinimumVersionPrefix} {release.systemMinimumVersion} — {release.systemTargetVersion}</Description>
            ) : null}
          </VersionsGroupItem>
        ))}
      </VersionsGroupItems>
    </VersionsGroup>
  );
};

const PageColumns = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  justify-content: space-between;
  margin-top: 0.125rem;
`;

const VersionsSidebar = styled.div`
  align-self: stretch;
`;

const VersionsGroup = styled.div``;

const VersionsGroupItems = styled.div`
  overflow: hidden;
  transition: 0.125s ease-in-out;
  margin: 0;
  padding-left: 1.25rem;
`;

const VersionsGroupItem = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.725rem;
  
  a {
    margin-bottom: 0;
  }
  
  ${Description} {
    font-size: 0.875rem;
    color: ${Colors.base3};
    opacity: 0.5;
    margin: 0;
    width: 9.125rem;
  }
`;

const VersionsGroupToggle = styled.div<{ collapsed: boolean }>`
  display: inline-flex;
  justify-content: space-between;
  width: 9.125rem;
  align-items: center;
  font-size: 1rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  text-decoration: none;
  border-bottom: 0;
  cursor: pointer;
  margin-bottom: 1.725rem;

  :hover {
    opacity: 0.7;
  }

  svg {
    vertical-align: middle;
    width: 1.5rem;
    height: 1.5rem;
    margin-left: 0.625rem;
    transition: transform 0.125s ease-in-out;
  }

  ${({ collapsed }) => collapsed ? css`
    svg {
      transform: rotate(-90deg);
    }
  ` : css`
    color: ${Colors.primary};
  `}
`;

const ChangelogInformationBlock = styled.div`
  max-width: 48.75rem;

  + h3 {
    margin-top: 0 !important;
  }
`;

const ChangelogVersionStyle = css`
  margin: 0 0 0.175rem;
  font-size: 2.1875rem;
  font-family: ${Fonts.Spartan};
  font-weight: bold;
  text-align: left;

  span {
    display: inline-block;
    vertical-align: top;
    margin-top: -0.125rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
    border: 2px solid ${Colors.primary};
    text-align: center;
    line-height: 1.7rem;
    transition: 0.3s ease-in-out;
  }
`;

const ChangelogVersion = styled.h3`
  ${ChangelogVersionStyle};
`;

const ChangelogVersionLink = styled(ExternalLink)`
  ${ChangelogVersionStyle};
  color: ${Colors.primary};
  
  &:hover {
    text-decoration: none;
    color: ${Colors.primary};

    span {
      background: ${Colors.primary};
      color: ${Colors.base1};
    }
  }
`;

const ChangelogAdditional = styled(Description)`
  display: block;
  color: ${Colors.base3Lighten};
  margin-bottom: 1.5625rem !important;

  ${mediaGeneralAdaptive} {
    margin-right: 0.625rem !important;

    &:last-of-type {
      margin-right: 0 !important;
    }
  }
`;

const ChangelogContainer = styled(DocumentationBlock)`
  > section {
    position: relative;
    clear: both;
    margin: 0 0 5rem;
  }

  ${List} {
    margin-bottom: 2rem;
  }

  ${Heading1}, ${Heading2}, ${Heading3} {
    margin: 2rem 0 0.625rem;
  }

  ${Heading4} {
    margin: 1.125rem 0 1.25rem;
  }
`;

type Release = {
  applicationVersion: string;
  date: string;
  systemMinimumVersionPrefix: string;
  systemMinimumVersion: string;
  systemTargetVersionPrefix: string;
  systemTargetVersion: string;
  hidden: boolean;
  description: string;
};

const parseMarkdownReleases = (markdown: string): Release[] => Array.from(markdown.matchAll(/^# (\d+\.\d+\.\d+), (\d{4}\.\d{2}\.\d{2}), ([\w\s]+?) (\d{2}\.?[\d]{0,2}) - ([\w\s]+?) (\d{2}\.?[\d]{0,2}),? ?([\w]+?)?\n([\s\S]*?)(?=\n# )/gm)).map((match) => ({
  applicationVersion: match[1],
  date: match[2],
  systemMinimumVersionPrefix: match[3],
  systemMinimumVersion: match[4],
  systemTargetVersionPrefix: match[5],
  systemTargetVersion: match[6],
  hidden: match[7] == 'hidden',
  description: match[8],
})).filter((release) => !release.hidden);

declare global {
  interface Number {
    padStart(maxLength: number, fillNumber: number): string;
  }
}

// eslint-disable-next-line func-names
Number.prototype.padStart = function (maxLength: number, fillNumber: number): string {
  return String(this)
    .padStart(maxLength, String(fillNumber));
};
