import { PADDLE_VENDOR_ID, SANDBOX_ENVIRONMENT } from '@Constant';

class PaddleHelperFactory {
  constructor(vendorId: number, isSandbox: boolean) {
    if (isSandbox) {
      Paddle.Environment.set('sandbox');
    }

    Paddle.Setup({
      vendor: vendorId,
      eventCallback: this.onCallback,
    });
  }

  private listeners: Map<Paddle.PaddleEventType, Paddle.PaddleEventCallback[]> = new Map();

  private onCallback = (data: Paddle.PaddleEvent) => {
    const listeners = this.listeners.get(data.event) ?? [];
    listeners.forEach((listener) => listener(data));
  };

  public subscribe = (eventName: Paddle.PaddleEventType, callback: Paddle.PaddleEventCallback) => {
    this.listeners.set(eventName, [...this.listeners.get(eventName) ?? [], callback]);
  };

  public unsubscribe = (eventName: Paddle.PaddleEventType, callback: Paddle.PaddleEventCallback) => {
    this.listeners.set(eventName, (this.listeners.get(eventName) ?? []).filter((listener) => listener !== callback));
  };
}

export const PaddleHelper = new PaddleHelperFactory(PADDLE_VENDOR_ID, SANDBOX_ENVIRONMENT);
